import React, { Fragment } from "react";

import Page from "lib/@cms/components/layout/Page";
import Feature from "lib/@cms/components/cms/Feature";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Divider from "lib/@cms/components/primitive/Divider";

function FinancialResourceCenter() {
  return (
    <Page pathname="financial-resource-center">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.GRAYSCALE}
              data={data.PageHeaderGradient[0]}
            />
            <Divider className="tw-mb-20 md:tw-mb-40" />

            <div className="tw-container">
              {data.FeaturesV2?.map((feature, index) => {
                return (
                  <Fragment key={`FeatureV2-${index}`}>
                    <Feature variant={Feature.variant.V2} data={feature} />

                    <Divider className="tw-mb-20 md:tw-mb-40" />
                  </Fragment>
                );
              })}
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default FinancialResourceCenter;
